import React from "react";
import "./navBar.css"; // Import the CSS file

const Navbar = ({ scrollToSection }) => {
    return (
        <nav>
            <ul>
                <li onClick={() => scrollToSection("intro")}>Intro</li>
                <li onClick={() => scrollToSection("about")}>About</li>
                <li onClick={() => scrollToSection("products")}>Products</li>
                <li onClick={() => scrollToSection("contact")}>Contact</li>
            </ul>
        </nav>
    );
};

export default Navbar;
