import React, { useRef, useState } from "react";
import "./contact.css";
import { useContext } from "react";
import emailjs from "@emailjs/browser";
import { ThemeContext } from "../../context";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4f2eia2",
        "template_9pc0c09",
        formRef.current,
        "uv7fhk6Jiw7SkOXC8"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div id="contact" className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss</h1>
          <div className="c-info">
            <div className="c-info-item">
              <i className="fa fa-phone" aria-hidden="true" />
              <p className="c-info-item-text">+977 9864253441</p>
            </div>
            <div className="c-info-item">
              <i className="fa fa-envelope" aria-hidden="true" />
              <p className="c-info-item-text"> prabindrasapkota89@gmail.com</p>
            </div>
            <div className="c-info-item">
              <i
                className="fa-solid fa-map-location-dot"
                aria-hidden="true"
              ></i>
              <p className="c-info-item-text">Koteshwor-35 ,Kathmandu ,Nepal</p>
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>What's your story?</b>
            <br />
            Get in touch.Always available to collaborate if the right project
            comes along me
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Name" required
              name="user_name"
            />
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Subject" required
              name="user_subject"
            />
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Email" required
              name="user_email"
            />
            <textarea
              style={{ backgroundColor: darkMode && "#333" }}
              rows={5}
              placeholder="Message" required
              name="message"
            />
            <button>Submit</button>
            {done && "Thank you . I will get back to you soon"}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
