import React from "react";
import "./product.css";
import defaultProductImg from "../../images/productbase.jpg";

const Product = ({ img, link, title }) => {
    return (
        <div className="p">
            <div className="p-browser">
                <div className="p-circle"></div>
                <div className="p-circle"></div>
                <div className="p-circle"></div>
            </div>
            <a href={link} target="_blank" rel="noreferrer">
                <img src={img} alt={defaultProductImg} className="p-img" />
                {title}
            </a>
        </div>
    );
};

export default Product;
