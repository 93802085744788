import React from "react";
import "./about.css";
import AboutBg from "../../images/AboutBg.png";
// import AboutImg from "../../images/AboutImg.png";

const About = () => {
    return (
        <div id="about" className="a">
            <div className="a-left">
                <div className="a-card bg"></div>
                <div className="a-card">
                    <img src={AboutBg} alt="" className="a-img" />
                </div>
            </div>
            <div className="a-right">
                <h1 className="a-title">About Me</h1>
                <p className="a-sub">
                    Hey, I'm all about Unity game development. From crafting captivating gameplay to optimizing performance, I thrive on bringing digital worlds to life. Let's dive into the realm of immersive gaming experiences together!".<br />
                </p>
                <p className="a-desc">
                    If you’d like to get in touch, feel free to say hello through any
                    social media or contact form below.
                </p>
                <br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/NED9Fs2UBBo?si=DHtr1Fkbmv4cpHza" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div className="social-network">
                    <ul className="social-icons" style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/prabindrasapkota/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i class="fa-brands fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/Parzival-101"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i class="fa-brands fa-github-square"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/Prabindra101"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i class="fa-brands fa-twitter-square"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/PrabindraSapkota101"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i class="fa-brands fa-facebook-square"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/prabindrasapkota/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i class="fa-brands fa-instagram-square"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="mailto:prabindrasapkota89@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-solid fa-envelope"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* <div className="a-award">
          <img src={AboutBg} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">Organizer at Tech-Trix</h4>
            <p className="a-award-desc">
              dolor sit Cum iure ad impedit quae, deserunt eligendi perferendis
              possimus, blanditiis, quasi laboriosam totam atque.
            </p>
          </div>
        </div> */}
            </div>
        </div>
    );
};

export default About;
