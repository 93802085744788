import "./intro.css";
import IntroImg from "../../images/baseimage.png";

const Intro = () => {
    return (
        <div id="intro" className="i">
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h2 className="i-intro">Hello ,I am </h2>
                    <h1 className="i-name">Prabindra Sapkota</h1>
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item">Game Developer</div>
                            <div className="i-title-item">.Net Developer</div>
                            <div className="i-title-item">ReactJs Developer</div>
                            <div className="i-title-item">Unity Developer</div>
                            {/*<div className="i-title-item">Student</div>*/}
                        </div>
                    </div>
                    <p className="i-desc">
                        I am passionate developer with number of experience in web
                        development ,game development , and C# work culture with knowledge
                        of C# , Unity3d ,ReactJs , .Net, SQL, Git and other basic .
                    </p>
                </div>
                {/* <i className="fa fa-arrow-circle-down i-scroll" aria-hidden="true"></i> */}
            </div>
            <div className="i-right">
                <div className="i-bg"></div>
                <img src={IntroImg} alt="" className="i-img" />
            </div>
        </div>
    );
};
export default Intro;
