import React from "react";
import "./productList.css";
import Product from "../product/product";
import {products} from "../../data";

export const ProductList = () => {
  return (
    <div id="products" className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">Working on something</h1>
        <p className="pl-desc">
          This is the section for my projects and works .
        </p>
      </div>
      <div className="pl-list">
          {products.map((x) =>{
            return <Product key={x.id} img={x.img} link={x.link} title ={x.title}/> 
          })}
      </div>
    </div>
  );
};
