export const products = [
    {
        id: 1,
        img: "https://raw.githubusercontent.com/Parzival-101/liveprofolio/main/src/images/GameImages/SlideBallGame.png?raw=true",
        link: "",
        title: "Slide Ball",
    },
    {
        id: 2,
        img: "https://github.com/Parzival-101/liveprofolio/blob/main/src/images/GameImages/pingpong.gif?raw=true",
        link: "",
        title: "Paddle Pong",
    },

    {
        id: 3,
        img: "https://raw.githubusercontent.com/Parzival-101/Hello-Sarkar/master/img.jpg",
        link: "https://github.com/Parzival-101/Hello-Sarkar",
        title: "Hello Sarkar",
    },
    {
        id: 4,
        img: "https://raw.githubusercontent.com/Parzival-101/Discord-Bot/master/image.jpg",
        link: "https://github.com/Parzival-101/Discord-Bot",
        title: "Discord Bot",
    },
    {
        id: 5,
        img: "https://raw.githubusercontent.com/Parzival-101/SoccerUpdated/master/img.jpg",
        link: "https://github.com/Parzival-101/SoccerUpdated",
        title: "Soccer Updated",
    },
    {
        id: 6,
        img: "https://raw.githubusercontent.com/Parzival-101/VidzyMVC/master/noimg.jpg",
        link: "https://github.com/Parzival-101/VidzyMVC",
        title: "Vidzy Mvc",
    },
];
