import React from "react";
import "./toggle.css";
import { useContext } from "react";
import { ThemeContext } from "../../context";

const Toggle = () => {
  const theme = useContext(ThemeContext);
  const handleClick = (e) => {
    theme.dispatch({ type: "TOGGLE" });
  };
  return (
    <div className="t">
      <i class="fas fa-sun t-icon"></i>
      <i class="fa-solid fa-moon t-icon" style ={{backgroundColor : "white" ,color : "#222"}}></i>
      <div
        className="t-button"
        onClick={handleClick}
        style={{ left: theme.state.darkMode ? 0 : 25 }}
      ></div>
    </div>
  );
};

export default Toggle;
