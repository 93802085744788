import { useContext } from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import { ProductList } from "./components/productList/productList";
import Toggle from "./components/toggle/Toggle";
import { ThemeContext } from "./context";
import Navbar from "./components/navBar/navBar";

const App = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div
            style={{
                backgroundColor: darkMode ? "#1a1a1a" : "#dddddd",
                color: darkMode ? "#e0e0e0" : "#333333",

            }}
        >
            <Navbar scrollToSection={scrollToSection} />

            <Toggle />

            <Intro id="intro" />
            <About id="about" />
            <ProductList id="products" />
            <Contact id="contact" />
        </div>
    );
};

export default App;
